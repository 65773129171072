(function($) {
  'use strict';

  $(document).ready(function () {
    let searchInput = $('#search-input'),
        recommendationsEnabled = (searchInput.attr('data-recommendations')!==undefined);

    if(recommendationsEnabled){

      let searchInitVal = searchInput.val(),
          searchRec = $('#search-recommendations'),
          searchRecCont = $('#search-recommendations-container');

      var checkRecommendations = function(){
        var searchInputVal = searchInput.val();
        if(searchInputVal.length >= 3){
          searchRec.show();
          if(searchInputVal !== searchInitVal){
            recommendations(searchInputVal);
            searchInitVal = searchInputVal;
          }
        } else {
          searchRec.hide();
        }
      };

      var recommendations = function(value){
        $.ajax({
          url: '/api/suggest',
          method: 'GET',
          data: value
        }).done(function (result) {
          searchRecCont.html('');
          $.each(result.suggestions, function (id, suggestion) {
            var encodedSuggestion = encodeURIComponent(suggestion);
            searchRecCont.append('<a href="' + searchInput.data('link') + '?s=' + encodedSuggestion + '" class="search-recommendation-item">' + suggestion + '</a>');
          });
        });
      };

      recommendations(searchInitVal);

      if(searchInput.length){
        searchInput.on('blur', function () {
          setTimeout(function(){ searchRec.hide(); }, 300);
        });

        searchInput.on('focus', function () {
          checkRecommendations();
        });

        searchInput.on('keyup', function (e) {
          if (e.which === 13) {
            window.location.href = $(this).data('link') + '?s=' + searchInput.val();
          } else {
            delay(function () {
              checkRecommendations();
            }, 300);
          }
        });
      }
    }
    else {
      searchInput.on('keyup', function (e) {
        if (e.which === 13) {
          window.location.href = $(this).data('link') + '?s=' + searchInput.val();
        }
      });
    }
  });
  var delay = (function(){
    var timer = 0;
    return function(callback, ms){
      clearTimeout (timer);
      timer = setTimeout(callback, ms);
    };
  })();
}(jQuery));
