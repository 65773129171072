(function($) {
  'use strict';

  //Add Custom Code

  $('.toggle-mobile-nav').on('click', function () {
    $('body').toggleClass('mobile-nav-visible');
  });

  var tocItem = $('#toclist').find('.collapse');

  tocItem.on('show.bs.collapse',function(){
    $(this).closest('.nav-item').addClass('active');
  });

  tocItem.on('hide.bs.collapse',function(){
    $(this).closest('.nav-item').removeClass('active');
  });

  window.myLazyLoad = new window.LazyLoad({
    elements_selector: '.lazy-load',
    callback_loaded: (el) => {
      var $el = $(el);
      if ($el.closest('[data-equalizer]').length) {
        $.pandoEqualizer();
      }
    }
  });

  $('.youtube-video-link').on('click', function () {
    $(this).html('<iframe class="embed-responsive-item" src="https://www.youtube-nocookie.com/embed/' + $(this).data('video-id') + '?autoplay=1" frameborder="0" allowfullscreen></iframe>');
    return false;
  });

  $('.vimeo-video-link').on('click', function () {
    $(this).html('<iframe class="embed-responsive-item" src="https://player.vimeo.com/video/' + $(this).data('video-id') + '?autoplay=1&dnt=1&h='+$(this).data('h-parameter')+'" frameborder="0" allowfullscreen></iframe>');
    return false;
  });

  $('.vimeo-load-thumb').each(function () {
    var $element = $(this);
    $.ajax({
      type:'GET',
      url: 'https://vimeo.com/api/oembed.json?url=' + encodeURIComponent('https://vimeo.com/' + $element.data('id'))+ '&width=1280',
      dataType: 'json',
      success: function(data) {
        $element.css('background-image', 'url(' + data.thumbnail_url + ')');
      }
    });
  });


  $(document).ready(function() {
    $('.image-link').magnificPopup({type:'image'});

    var sidebarLeft = $('.sidebar-left');
    var navItemActive = $('.nav-item.active');
    if(sidebarLeft.length && navItemActive.length){
      sidebarLeft.scrollTop(navItemActive.offset().top - 89);
    }
  });


  let currentHash = "#initial_hash";
  $(document).scroll(function () {
    $('.anchor a').each(function () {
      let top = window.pageYOffset;
      let distance = top - $(this).offset().top;
      let hash = $(this).attr('id');
      if (distance < 50 && distance > 0 && currentHash !== hash) {
        window.location.hash = (hash);
        currentHash = hash;
      }
    });
  });

  var validUntilDays = $('#validUntilDays');
  var validDays = $('#validDays');

  validUntilDays.on('keyup', function(e){
    checkTokenForm(e.which);
  });
  validDays.on('keyup', function(e){
    checkTokenForm(e.which);
  });

  function checkTokenForm(){
    $('generatedTokenContainer').hide();
    if(validUntilDays.val().length && validDays.val().length){
      $('#submitTokenForm').prop('disabled', false);
    } else {
      $('#submitTokenForm').prop('disabled', true);
    }
  }

  $('#copyToken').on('click', function() {
    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {  // IE?
      document.selection.empty();
    }

    var copyText= document.querySelector('#generatedToken');
    var range = document.createRange();
    range.selectNode(copyText);
    window.getSelection().addRange(range);
    try {
      var successful = document.execCommand('copy');
      if(successful) {
        toastr.success('Copied token to clipboard');
      }
    } catch(err) {

    }
    window.getSelection().removeAllRanges();
  });

  $('.api-request-code-select').on('change', function() {
    window.setCookie('codeLang', $(this).val(), 356);
    window.location.reload();
  });

  /*
  const checkHash = function () {
    var hash = window.location.hash;
    if(hash) {
      hash = hash.slice(1);
      var targetObject = $('.api-object-' + hash);
      if(targetObject.length) {
        targetObject.collapse('show');
      }
      var targetCall = $('.api-call-' + hash);
      if(targetCall.length) {
        targetCall.collapse('show');
      }
    }
  };
  checkHash();

  $(window).bind('hashchange', function() {
    checkHash();
  });
  */

}(jQuery));
